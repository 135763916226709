
.main {
   padding: 20px;
   margin-top: 72.2px;
}


th, td {
   padding: 0 5px;
   text-align: left;
}

table.card-table {
   display: flex;
   justify-content: space-between;

   & > div {
      margin: 0 10px;
   }
}


button {
   outline: none;
   border: none;
}

.toolbar {
   position: sticky;
   top: 72.5px;
   z-index: 1000;
   margin-bottom: 20;
   background-color: #fff;
   padding: 0 0 20px 0;
}

.upload-input {
   height: max(60vh, 400px) !important;
   resize: none;
}

.event-card {
   cursor: pointer;
   width: 100%;

   &.highlight {
      border: 2px solid #00BFFF;
   }

   &:hover > * {
      border: 1px solid #00BFFF;
   }

   button.settings {
      background-color: rgba(0,0,0,0);
      cursor: pointer;
      
   }

}

.event-card-container {
   min-width: 350px;
}
.event-card-container.highlight > .event-card > * {
   border: 2px solid #00BFFF;
}


table.editable-table {

   th, td {
      padding-bottom: 10px;
      vertical-align: top;
   }
   td {
      padding-left: 20px;
   }
   th {
      min-width: 167px;
   }
   tr {
      width: 100%;
      position: relative;
   }

   tr:nth-child(6) > * {
      padding-bottom: 40px;
   }

   tr:nth-child(10) > * {
      padding-bottom: 40px;
   }
}

.ticket-field {
   color: grey !important;
   cursor: pointer;

   .ant-typography-edit {
      color: grey !important;
   }

   &:hover {
      color: #00BFFF !important;
      transition: 0s;

      .ant-typography-edit {
         color: #00BFFF !important;
         transition: 0s;
      }
   }

   textarea.ant-input {
      padding-right: 55px;
      max-height: 150px !important;
   }
}

.tech-notes-notice {
   margin-bottom: 2px;

   * {
      color: #4FFFB0 !important;
      font-weight: 600 !important;
   }
}

.site-drawer-render-in-current-wrapper {
   position: relative;
   padding: 48px;
   overflow: hidden;
   text-align: center;
   background: #fafafa;
   border: 1px solid #ebedf0;
   border-radius: 2px;
}

.help-icon {
   font-size: 15;
   margin-left: 10px;
   cursor: pointer;

   &:hover {
      color: #00BFFF;
   }
}

.event-settings {
   font-size: 20px !important;
   color: #b7b7b7 !important;
   transition: 0.1s linear;

   &:hover {
      color: #949494 !important;
      transform: rotate(35deg);
      transition: 0.1s linear;
   }
}


.grid-container {
   margin-bottom: 8px;
   display: grid;
   position: relative;
   grid-template-columns: 180px minmax(0, 1fr);

   .header {
      font-weight: bold;
   }


   &:nth-of-type(7),
   &:nth-of-type(11) {
      margin-bottom: 30px;
   }
}