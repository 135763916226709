
.login-page {
   width: 100vw;
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
   background: url(../../Images/background.svg) no-repeat;
   background-size: cover;
   background-color: #06083d;
}

.login {
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   backdrop-filter: blur(4px);
}

h1 {
   color: #fff !important;
}

.input-field {
   display: flex;
   justify-content: center;
   align-items: center;
   border-bottom: 2px solid #dddddd;

   input {
      font-size: 30px;
      color: #fff;
      padding-right: 10px;
      border: none;
      outline: none;
      background-color: rgba(0,0,0,0);
   }

   span {
      cursor: pointer;
   }
}

.bar {
   border-top: 3px solid rgb(50, 159, 247);
   width: 0%;
   margin-top: -26px;
   transition: 0.3s ease;

   &.focus {
      width: 100%;
      transition: 0.3s ease;
   }

   &.error {
      width: 100%;
      border-color: red;
   }
}

