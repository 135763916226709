
body {
   background-color: #fff;
   overflow-x: hidden;
}

body::-webkit-scrollbar {
   display: none;
   width: 0;
}

*::-webkit-scrollbar {
   display: none;
   width: 0;
}